import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            component: (resolve) => (require(['@/pages/home/index.vue'], resolve))
        },
        {
            path: '/aboutUs',
            name: 'aboutUs',
            component: (resolve) => (require(['@/pages/aboutUs/index.vue'], resolve))
        },
        {
            path: '/contact',
            name: 'contact',
            component: (resolve) => (require(['@/pages/contactUs/index.vue'], resolve))
        },
        {
            path: '/recruit',
            name: 'recruit',
            component: (resolve) => (require(['@/pages/recruit/index.vue'], resolve))
        }
    ]
})