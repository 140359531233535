<template>
  <div class="header-wrapper">
    <div class="logo">
      <img src="../../assets/images/logo.png" />
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="首页" name="home"></el-tab-pane>
      <el-tab-pane label="招聘人才" name="recruit"></el-tab-pane>
      <el-tab-pane label="关于我们" name="aboutUs"></el-tab-pane>
      <el-tab-pane label="联系我们" name="contact"></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data(){
    return {
      activeName: 'home'
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(newVal) {
        console.log(newVal,'path')
        this.initTab()
      }
    }
  },
  mounted() {
    this.initTab()
  },
  methods: {
    handleClick() {
      this.$router.push('/'+this.activeName)
    },
    initTab() {
      const path = this.$route.path.split('/')
      console.log(path)
      this.activeName = path[path.length-1] || 'home'
    }
  }
}
</script>

<style lang="less" scoped>
.header-wrapper{
  height: 60px;
  display: flex;
  padding: 0 240px;
  align-items: center;
}
.logo{
  margin-right: 80px;

  img{
    width: 134px;
    height: 38px;
  }
}
</style>