<template>
  <div class="footer-wrapper">
    <p class="company-name">医路行（杭州）科技有限公司</p>
    <div class="footer-item">
      <img src="../../assets/images/icon/location.png" />
      <span>浙江省杭州市运河东路378号4幢1608室</span>
    </div>
    <div class="footer-item">
      <img src="../../assets/images/icon/phone.png" />
      <span>0571-85239098</span>
    </div>
    <div class="footer-item">
      <img src="../../assets/images/icon/mail.png" />
      <span>yshi@yluxing.com</span>
    </div>
    <div class="qrcode">
      <img src="../../assets/images/gz_qrcode.png" />
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn" target="blank">浙ICP备2023004614号-1</a>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.footer-wrapper{
  height: 214px;
  background: #0e1421;
  padding: 30px 10%;
  box-sizing: border-box;
  position: relative;
}
.company-name{
  font-size: 20px;
  color: #ffffff;
  line-height: 28px;
  margin-bottom: 20px;
}
.footer-item{
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;

  img {
    width: 12px;
    height: 14px;
    margin-right: 10px;
  }
}
.beian{
  text-align: center;
  line-height: 20px;
  a{
    opacity: 0.6;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
}
.qrcode{
  position: absolute;
  right: 10%;
  top: 35px;

  img{
    width: 120px;
  }
}
</style>