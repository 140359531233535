<template>
  <div id="app">
    <web-header></web-header>
    <router-view></router-view>
    <web-footer></web-footer>
  </div>
</template>

<script>
import WebHeader from '../src/components/Header'
import WebFooter from '../src/components/Footer'
export default {
  name: 'App',
  components: {
    WebHeader,
    WebFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: initial;
}
.header-wrapper .el-tabs__nav-wrap::after{
  height: 0;
}
.flex{
  display: flex;
  align-items: center;
}
.border-bottom{
  border-bottom: 1px solid rgb(239, 239, 239);
}
.toastindex {
  z-index: 5000 !important;
}
.page-container{
  background-color: #f7f7f7;
  padding: 10px;
  height: 100vh;
}
.card{
  border-radius: 8px;
  background: #fff;
  padding: 10px 20px;
  border: 1px solid rgb(241, 241, 241);
  margin-bottom: 10px;
}
.required .van-cell__title::before{
  content: '*';
  color: red;
  position: absolute;
  left: 8px;
}
input,input:focus,input:active{
  user-select: text;
}
.van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
  color: #ccc !important;
}
.banner .image-slot, 
.sub-banner .image-slot{
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  background: #f5f7fa;
  color: #909399;
}
.sub-banner .image-slot{
  min-height: 400px;
}
.banner .el-image, 
.sub-banner .el-image{
  width: 100%;
}
.recruit-banner .el-image{
  width: 95%;
}
.about-wrapper .image-slot{
  min-height: 270px;
  height: 100%;
}
</style>
